<template>
  <div class="line_box">
    <div class="top_box">
      <div class="topOperation_content" @click="addEvent('add')">
        <img class="topFunction_img" src="https://hyzg-app.oss-cn-beijing.aliyuncs.com/hanDuck/xinzeng.png" alt="">
        <div>新增</div>
      </div>

      <div class="topOperation_content" @click="addEvent('copy')">
        <img class="topFunction_img" src="https://hyzg-app.oss-cn-beijing.aliyuncs.com/hanDuck/fuzhi.png" alt="">
        <div>复制</div>
      </div>
      <div class="topOperation_content" @click="distribution">
        <img class="topFunction_img" src="https://hyzg-app.oss-cn-beijing.aliyuncs.com/hanDuck/kehufenpei.png" alt="">
        <div>分配承运商</div>
      </div>
      <div class="topOperation_content" @click="addEvent('edit')">
        <img class="topFunction_img" src="https://hyzg-app.oss-cn-beijing.aliyuncs.com/hanDuck/bianji.png" alt="">
        <div>编辑</div>
      </div>
      <div class="topOperation_content" @click="deleteEvent">
        <img class="topFunction_img" src="https://hyzg-app.oss-cn-beijing.aliyuncs.com/hanDuck/shanchu.png" alt="">
        <div>删除</div>
      </div>
      <div class="topOperation_content" @click="termination">
        <img class="topFunction_img" src="https://hyzg-app.oss-cn-beijing.aliyuncs.com/hanDuck/xianshizhongzhi.png" alt="">
        <div>人工终止</div>
      </div>

    </div>

    <div class="filter_Feature_Style">
      <div class="filter_Feature_items">
        <div class="filter_Feature_content">
          <div class="screen_title">始发站：</div>
          <el-cascader @keydown.native="trimLR" v-model="startAddress" filterable :options="options" clearable @change="(val)=> handleChange(val,'1')" :props="{value: 'code',label:'name', children:'children',checkStrictly: true}" size="mini" class="screenInput"></el-cascader>
        </div>
        <div class="filter_Feature_content">
          <div class="screen_title">目的站：</div>
          <el-cascader @keydown.native="trimLR" v-model="endAddress" filterable :options="options" clearable @change="(val)=> handleChange(val,'2')" :props="{value: 'code',label:'name', children:'children',checkStrictly: true}" size="mini" class="screenInput"></el-cascader>
        </div>
        <div class="filter_Feature_content">
          <div class="screen_title">承运商：</div>
          <el-input class="screenInput" size="mini" v-model.trim="params.companyName" clearable></el-input>
        </div>
        <div class="filter_Feature_content">
          <div class="screen_title">时效类型：</div>
          <el-select class="screenInput" v-model="params.speedType" placeholder="请选择" size="mini" clearable>
            <el-option v-for="item in agingList" :key="item.key" :label="item.value" :value="item.key"></el-option>
          </el-select>
        </div>
        <div class="filter_Feature_content">
          <div class="screen_title">产品类型：</div>
          <el-select class="screenInput" v-model="params.goodsType" placeholder="请选择" size="mini" clearable>
            <el-option v-for="item in goodsList" :key="item.key" :label="item.value" :value="item.key"></el-option>
          </el-select>
        </div>
        <div class="filter_Feature_content">
          <div class="screen_title">有效状态：</div>
          <el-select class="screenInput" v-model="params.validFlg" placeholder="请选择" size="mini" clearable>
            <el-option v-for="item in statusList" :key="item.key" :label="item.value" :value="item.key"></el-option>
          </el-select>
        </div>
      </div>
      <div class="query_btn">
        <el-button type="reset" size="mini" @click="reset">重 置</el-button>
        <el-button type="button" size="mini" @click="query">查 询</el-button>
      </div>
    </div>

    <div class="table_box">
      <el-table :data="tableData" border style="width: 100%" v-loading="tableLoading" element-loading-text="数据正在加载中..." :header-cell-style="{ 
      background:'#ebeef5',hight:'50px',fontSize:'12px'}" @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55" align="center"></el-table-column>
        <el-table-column prop="pathCode" label="线路ID" align="center" width="220">
          <template slot-scope="scope">
            <span class="details_style" @click="viewDetails(scope.row)">{{scope.row.pathCode}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="cityFrom" label="城市-城市" align="center" width="150"></el-table-column>
        <el-table-column prop="startProvinceName" label="始发地省" align="center" width="150"></el-table-column>
        <el-table-column prop="startCityName" label="始发地市" align="center"></el-table-column>
        <el-table-column prop="startAreaName" label="始发地县" align="center"></el-table-column>
        <el-table-column prop="endProvinceName" label="目的地省" align="center" width="150"></el-table-column>
        <el-table-column prop="endCityName" label="目的地城市" align="center"></el-table-column>
        <el-table-column prop="endAreaName" label="目的地县" align="center"></el-table-column>
        <el-table-column prop="companyName" label="承运商" align="center" width="200"></el-table-column>
        <el-table-column label="时效类型" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.speedType == 1">标准</span>
            <span v-if="scope.row.speedType == 2">普快</span>
            <span v-if="scope.row.speedType == 3">特快</span>
          </template>
        </el-table-column>
        <el-table-column prop="goodsTypeVal" label="产品类型" align="center"></el-table-column>
        <el-table-column prop="validFlgVal" label="有效状态" align="center"></el-table-column>
        <el-table-column prop="newTime" label="有效时间" width="200" align="center">
          <!-- <template slot-scope="scope">
            <span>{{scope.row.validStart}}-{{scope.row.validEnd}}</span>
          </template> -->
        </el-table-column>
        <el-table-column prop="createAt" label="新建时间" align="center" width="150"></el-table-column>
        <el-table-column prop="updateAt" label="最新编辑时间" align="center" width="150"></el-table-column>
      </el-table>

      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" small background :current-page="params.pageNum" :page-size="params.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </div>

    <el-dialog title="分配承运商" :visible.sync="centerDialogVisible" width="35%" center :before-close="cancelShare">
      <div class="share_box">
        <div class="title"><span class="sign">*</span>承运商</div>
        <el-select class="screenInput" v-model="carrier" placeholder="请选择" size="mini" clearable>
          <el-option v-for="item in findCompName" :key="item.id" :label="item.compName" :value="item.id"></el-option>
        </el-select>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="reset" size="mini" @click="cancelShare">取 消</el-button>
        <el-button type="button" size="mini" @click="shareCarrier">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  lineList,
  agingType,
  productType,
  validStatus,
  addressList,
  lineDeletion,
  terminationList,
  getFindCompName,
  setCompany, //分配承运商
} from "@/api/basics";
import utils from "@/plugin/utils/utils";
export default {
  name: "/line/line",
  data() {
    return {
      tableData: [],
      startAddress: [], //始发站：
      endAddress: [], //目的站
      options: [],
      params: {
        pageSize: 10,
        pageNum: 1,
      },
      total: 0,
      agingList: [], //时效类型
      goodsList: [], //产品类型
      statusList: [], //有效状态
      multipleSelection: [],
      tableLoading: false,
      centerDialogVisible: false,
      findCompName: [], // 承运商
      carrier: "", //分配承运商
    };
  },
  activated() {
    console.log("哎呀看见我了");
  },
  mounted() {
    this.getList();
    this.getAging(); //时效类型
    this.getProduct(); //产品类型
    this.getStatus(); //有效状态
    this.getAddress(); //省市区
  },
  methods: {
    query() {
      this.params.pageNum = 1;
      this.getList();
    },

    async getList() {
      try {
        this.tableLoading = true;
        //起始地
        if (this.startAddress.length > 0) {
          this.params.startProvinceCode = this.startAddress[0];
          this.params.startCityCode = this.startAddress[1];
          this.params.startAreaCode = this.startAddress[2]
            ? this.startAddress[2]
            : "";
        }
        //目的地
        if (this.endAddress.length > 0) {
          this.params.endProvinceCode = this.endAddress[0];
          this.params.endCityCode = this.endAddress[1];
          this.params.endAreaCode = this.endAddress[2]
            ? this.endAddress[2]
            : "";
        }

        // console.log(this.params, "params");
        let data = utils.removeNull(this.params); //去除空字符串
        var res = await lineList(data);
        if (res.retCode == "0000000") {
          var items = this.province(res.rspBody.items);
          // console.log(items, "列表");
          items.forEach((val) => {
            if (val.validStart && val.validEnd) {
              let time1 = val.validStart.split(" ");
              let time2 = val.validEnd.split(" ");
              let newTime = time1[0] + " - " + time2[0];
              val.newTime = newTime;
            }
          });
          this.tableLoading = false;
          this.tableData = items;
          this.total = res.rspBody.total;
        } else {
          this.tableLoading = false;
          this.$message({
            type: "error",
            message: res.message,
          });
        }
      } catch (err) {
        console.log(err);
        this.tableLoading = false;
        this.$message({
          type: "error",
          message: err.message,
        });
      }
    },

    viewDetails(row) {
      console.log(row, "row");
      this.$router.push({
        path: "/line/addLine",
        query: {
          row: row,
          status: 'details',
        },
      });
    },

    province(list) {
      list.forEach((value) => {
        //城市-城市数据组合
        if (value.startCityName && value.endCityName) {
          value.cityFrom = value.startCityName + "-" + value.endCityName;
        } else if (value.startProvinceName && value.endProvinceName) {
          value.cityFrom =
            value.startProvinceName + "-" + value.endProvinceName;
        }
      });
      return list;
    },

    handleSelectionChange(val) {
      console.log(val, "列表选中");
      this.multipleSelection = val;
    },

    //重置
    reset() {
      this.params = utils.clearQuery(this.params);
      this.params.pageSize = 10;
      this.params.pageNum = 1;
      this.startAddress = [];
      this.endAddress = [];
      console.log(this.params, "重置");
      this.getList();
    },

    trimLR(e) {
      if (e.keyCode == 32) {
        e.returnValue = false;
      }
    },

    handleChange(value, index) {
      console.log(value, "省市区");
      if (index == 1) {
        this.params.startAreaCode = "";
        this.params.startCityCode = "";
        this.params.startProvinceCode = "";
      } else {
        this.params.endAreaCode = "";
        this.params.endCityCode = "";
        this.params.endProvinceCode = "";
      }
    },

    //时效类型
    async getAging() {
      try {
        let res = await agingType();
        this.agingList = res.rspBody;
      } catch (err) {
        console.log(err);
      }
    },

    //产品类型
    async getProduct() {
      try {
        let res = await productType();
        this.goodsList = res.rspBody;
      } catch (err) {
        console.log(err);
      }
    },

    //有效状态
    async getStatus() {
      try {
        let res = await validStatus();
        this.statusList = res.rspBody;
      } catch (err) {
        console.log(err);
      }
    },

    //省市区处理
    async getAddress() {
      try {
        let res = await addressList({});
        var list = res.rspBody;
        if (list.length > 0) {
          this.options = utils.addressData(list);
        } else {
          this.options = [];
        }
      } catch (err) {
        console.log(err);
      }
    },

    addEvent(status) {
      console.log(status, "status");
      if (status != "add") {
        if (this.multipleSelection.length == 0) {
          this.$message({
            type: "warning",
            message: "请选择数据",
          });
          return;
        }
        if (this.multipleSelection.length > 1) {
          this.$message({
            type: "warning",
            message: "请选择一条数据",
          });
          return;
        }
      }

      var row = this.multipleSelection[0];
      this.$router.push({
        path: "/line/addLine",
        query: {
          status: status,
          row: row,
        },
      });
    },

    //分配承运商
    async distribution() {
      var that = this;
      if (that.multipleSelection.length == 0) {
        this.$message({
          type: "warning",
          message: "请选择数据",
        });
        return;
      }
      if (that.multipleSelection.length > 1) {
        this.$message({
          type: "warning",
          message: "请选择一条数据",
        });
        return;
      }
      console.log(that.multipleSelection[0]);
      let obj = that.multipleSelection[0];
      if (obj.companyId) {
        this.carrier = obj.companyId;
      }

      try {
        let res = await getFindCompName({});
        console.log(res, "res");
        if (res.retCode == "0000000") {
          that.findCompName = res.rspBody.items;
          let data = {
            compName: "无",
            id: "-1",
          };
          that.findCompName.unshift(data);
          that.centerDialogVisible = true;
        } else {
          this.$message({
            type: "error",
            message: res.retDesc,
          });
        }
      } catch (error) {
        this.$message({
          type: "error",
          message: error.retDesc,
        });
      }
    },

    async shareCarrier() {
      console.log(this.carrier, "this.carrier");
      if (this.carrier == "") {
        this.$message({
          type: "warning",
          message: "请选择承运商",
        });
        return;
      }
      try {
        let data = {
          id: this.multipleSelection[0].id,
          companyId: this.carrier,
        };
        console.log(data);
        let res = await setCompany(data);
        console.log(res, "res");
        if (res.retCode == "0000000") {
          this.$message({
            type: "success",
            message: "分配成功",
          });
          this.cancelShare();
          this.getList();
        } else {
          this.$message({
            type: "error",
            message: res.retDesc,
          });
        }
      } catch (error) {
        console.log(error);
        this.$message({
          type: "error",
          message: error.retDesc,
        });
      }
    },

    cancelShare() {
      this.carrier = "";
      this.centerDialogVisible = false;
    },

    //删除
    deleteEvent() {
      if (this.multipleSelection.length == 0) {
        this.$message({
          type: "warning",
          message: "请选择数据",
        });
        return;
      }
      if (this.multipleSelection.length > 1) {
        this.$message({
          type: "warning",
          message: "请选择一条数据",
        });
        return;
      }
      if (this.multipleSelection[0].validFlg != 2) {
        this.$message({
          type: "warning",
          message: "只可删除待生效的数据",
        });
        return;
      }
      this.$confirm("确认要删除该数据吗, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.confirmDeletion();
        })
        .catch(() => {});
    },

    //删除
    async confirmDeletion() {
      try {
        let data = {
          id: this.multipleSelection[0].id,
        };
        let res = await lineDeletion(data);
        console.log(res, "resres");
        if (res.retCode == "0000000") {
          this.$message({
            type: "success",
            message: "删除成功",
          });
          this.getList();
        } else {
          this.$message({
            type: "error",
            message: res.retDesc,
          });
        }
      } catch (err) {
        console.log(err, "err");
        this.$message({
          type: "error",
          message: err.retDesc,
        });
      }
    },
    //人工终止
    termination() {
      if (this.multipleSelection.length == 0) {
        this.$message({
          type: "warning",
          message: "请选择数据",
        });
        return;
      }
      if (this.multipleSelection.length > 1) {
        this.$message({
          type: "warning",
          message: "请选择一条数据",
        });
        return;
      }
      if (this.multipleSelection[0].validFlg != 1) {
        this.$message({
          type: "warning",
          message: "只可终止已生效的数据",
        });
        return;
      }
      this.$confirm("确认要终止该数据吗, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.manualTermination();
        })
        .catch(() => {});
    },

    //人工终止
    async manualTermination() {
      try {
        let data = {
          id: this.multipleSelection[0].id,
        };
        let res = await terminationList(data);
        console.log(res, "resres");
        if (res.retCode == "0000000") {
          this.$message({
            type: "success",
            message: "人工终止成功",
          });
          this.getList();
        } else {
          this.$message({
            type: "error",
            message: res.retDesc,
          });
        }
      } catch (err) {
        console.log(err, "err");
        this.$message({
          type: "error",
          message: err.retDesc,
        });
      }
    },

    handleSizeChange(val) {
      this.params.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.params.pageNum = val;
      this.getList();
    },
  },
};
</script>

<style lang="scss" scoped>
.line_box {
  height: 100%;
  width: 100%;

  .details_style {
    color: #086ffd;
    cursor: pointer;
  }

  .share_box {
    display: flex;
    align-items: center;
    .title {
      padding-right: 10px;
      .sign {
        color: #f56c6c;
        padding-right: 5px;
      }
    }
  }
}
</style>